<template>
  <div style="position: relative">
    <div class="header_List">
      <ul>
        <li v-for="item in headertest" :key="item.id">{{ item.name }}</li>
        <li class="about_us">About Us
        <div>
          <img src="@/assets/img/abotme.png" alt="">
        </div>
        </li>
      </ul>
    </div>
    <div class="content_List">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="item in imgin" :key="item">
          <img width="100%" height="100%" :src="item.img" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="position: absolute;
    width: 100%;
    top: 37.3rem;
    left: 0;">
      <div class="border_tu">
        <div class="border_left"></div>
        <div class="border_conten"></div>
        <div class="border_right"></div>
        <div class="-date-table-cell__text">
          <h1>Future Forward</h1>
          <p>The pioneer of the natural gas industry in the country, Pakistan Petroleum Limited (PPL) has been a
            frontline player in the energy sector since the mid-1950s. As a major supplier of natural gas, PPL today
            contributes around 20 percent of the country's total natural gas supplies beside producing crude oil,
            Natural Gas Liquid and Liquefied Petroleum Gas.</p>
        </div>
      </div>

    </div>
    <div class="contenner">
      <div class="cat_dome">
        <div class="cat_dome_left">
          <h1>Exploration</h1>
          <p>Pakistan Petroleum Limited (PPL) has an aggressive exploration program geared to optimize production and
            replenish reserves. The company has invested in acquiring state-of-the-art integrated technology and
            enhancing human resource competencies to achieve desired outputs and minimize risks through well-coordinated
            teamwork and strategic partnerships with local and international Exploration and Production companies.</p>
          <p>The Exploration & Business Development (E&BD) Directorate consists of three Assets; South, North and
            Frontier Asset (Frontier Asset includes a block in Iraq) and also looks after partner-operated offshore
            blocks in Pakistan and one partner operated onshore block in Yemen. In addition, New Venture, International
            & Unconventional (NVIU) and Subsurface Technologies & Support Services (STSS) departments also fall under
            the domain of E&BD Directorate.</p>
          <div class="more_btn"><span>More</span> <a>Activity Map</a></div>

        </div>
        <div class="cat_dome_right">
          <img width="100%" src="@/assets/img/exploration_2_0.jpg" alt="">
        </div>
      </div>

    </div>
    <div class="test_tows">
      <h1>Production</h1>
      <p>Pakistan Petroleum Limited (PPL) operates 13 production fields in Sui, Kandhot, Adhi, Mazarani, Chachar, Adam,
        Adam west Shahdadpur, Shahdadpur west, Shahdadpur east , Fazal and Dhok Sultan - the first two wholly owned by
        PPL - and has working interest in 23 partner -operated producing assets.</p>
      <p>Daily gas production of PPL from its operated and partner-operated fields stands around one billion cubic feet
        equivalent (bcfe) of gas per day, which translates into nearly 22 percent of the country's total population (gas
        equivalent). The company's major clients comprises Sui Southern Gas Company Limited (SSGCL), Sui Northern Gas
        Pipelines Limited (SNGPL) and Water and Power Development Authority</p>
      <div class="more_btn_tows"><span>More</span> <a>Activity Map</a></div>
    </div>
    <div class="cat_List_demo">
      <div class="car_item" v-for="item in 4" :key="item">
        <div>
          <img src="@/assets/img/production-assets-icon.webp" alt="">
          <h3>{{ getTitle(item) }}</h3>
          <p>Pakistan Petroleum Limited operates producing fields in Balochistan, Sindh and Punab.</p>
        </div>
      </div>
    </div>
    <div class="contenner">
      <div class="cat_dome">
        <div class="cat_dome_right">
          <h2>PPL-operated Fields</h2>
          <img width="100%" src="@/assets/img/Adhi-Box.webp" alt="">
        </div>
        <div class="cat_dome_left" style="padding-top: 3rem">
          <h2 style="color:#ed8b00;">Mazarani Gas Field</h2>
          <ul style="padding: 0">
            <li v-for="item in 4" :key="item" style="list-style: none;    list-style: none;
    font-family: system-ui;
    font-weight: 600;
    color: #7c7c7c;">
              {{ getTitletow(item) }}
            </li>
          </ul>
          <p style="font-size: 0.9rem;
    color: #8f8f8f;">Gambat South is located in District Sanghar, Sindh. The block was granted to PPL in December 2009.
            Later, the company farmed-out 10 percent of its working interest in Gambat South to Asia Resources Oil
            Limited and 25 percent to Government Holdings (Private) Limited in June and July 2010, respectively.</p>
          <div class="more_btn"><span>Explore</span></div>
        </div>
      </div>
    </div>
    <div class="indiye_bg">
    </div>
    <div class="footer">
      <div class="footer-container">
        <div class="block-footerppllogo" style="font-size: 2rem;color:white;">PEHLAAJ TRADERS</div>
        <div class="block-bootstrap-barrio-ppl-footer">
          <div>
            <p>Home</p>
            <p>Contact</p>
            <p>Contact</p>
            <p>Terms</p>
            <p>Whistle Blowing Policy</p>
            <p>Sitemap</p>
            <p>Privacy</p>
          </div>
          <div>
            <p>Home</p>
            <p>Contact</p>
            <p>Contact</p>
            <p>Terms</p>
            <p>Whistle Blowing Policy</p>
            <p>Sitemap</p>
            <p>Privacy</p>
          </div>
          <div>
            <p>Home</p>
            <p>Contact</p>
            <p>Contact</p>
            <p>Terms</p>
            <p>Whistle Blowing Policy</p>
            <p>Sitemap</p>
            <p>Privacy</p>
          </div>
        </div>
        <div class="block-footerimages">
          <img src="@/assets/img/jama.webp" />
          <img src="@/assets/img/service-desk.webp" style="margin-top: 1rem;margin-bottom:3rem" />
          <div>
            <img src="@/assets/img/linkedin-icon.webp" />
            <img src="@/assets/img/twitter-new-logo_0.webp" style="margin:0 1rem"/>
            <img src="@/assets/img/fb-logo.webp" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const headertest = [
  {id: 1, name: 'Profile'},
  {id: 2, name: 'Investors'},
  {id: 3, name: 'Exploration'},
  {id: 4, name: 'Production'},
  {id: 5, name: 'Community'},
  {id: 5, name: 'QHSE'},

]
const titles = {
  1: 'Production Assets',
  2: 'Partner-operated Fields',
  3: 'Production Summary: 2013-23',
  4: 'Bolan Mining Enterprises'
};
const imgin=[{
  img: require('@/assets/img/home-page-slide-01_0_0.webp'),
},
  {
    img: require('@/assets/img/Morgandh X-1 home pg_0.webp'),
  }]
const titles2 = {
  1: 'Area: 19.55 sq.km',
  2: 'Operator: PPL',
  3: 'PPL Working Interest: 65 percent',
  4: 'Hydrocarbon Type: Gas/Condensate'
};
const getTitletow = (item) => {
  return titles2[item] || '';
};
const getTitle = (item) => {
  return titles[item] || '';
};
</script>
<style>
.indiye_bg {
  background-image: url('@/assets/img/pxceonten.png');
  height: 45rem;
  width: 100%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
}
.about_us:hover {
  background-color: #ed8b00;
  color: #fff;
  cursor: pointer;
  position: relative;
  div {
    display: flex;
    z-index: 9999;
    right: 0;
  }
}

.about_us{
  background-color: #ed8b00;
  color: #fff;
  cursor: pointer;
  position: relative;
  div {
    display: none;
    position: absolute;
    top: 3rem;
  }
}
</style>



